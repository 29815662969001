$(function () {


  // add easeInOutCubic easing
  $.extend($.easing, {
      easeInOutCubic: function(x, t, b, c, d) {
          if ((t /= d / 2) < 1) return c / 2 * t * t * t + b;
          return c / 2 * ((t -= 2) * t * t + 2) + b;
      },
  });

  /* homepage slick slider
     ===================================== */

  if ( $(".gallery-container").length) {

    $('.gallery-container').slick({
      autoplay: true,
      infinite: true,
      autoplaySpeed: 12000,
      fade: true,
      dots: false,
      nextArrow: false,
      prevArrow: false,
      touchThreshold: 8
    });
  }
});
